const IndexPage = () => import(/* webpackChunkName: "js/1001" */ "./pages/web/index.vue");
const OurSpecialtyPage = () => import(/* webpackChunkName: "js/1002" */ "./pages/web/our_specialties.vue");
const OrderPage = () => import(/* webpackChunkName: "js/1003" */ "./pages/web/order.vue");
const CheckoutPage = () => import(/* webpackChunkName: "js/1003" */ "./pages/web/checkout.vue");
const CheckoutConfirmationPage = () => import(/* webpackChunkName: "js/1004" */ "./pages/web/checkout_confirmation.vue");
const DishMenuPage = () => import(/* webpackChunkName: "js/1005" */ "./pages/web/dish_menu.vue");
const TestimonialsPage = () => import(/* webpackChunkName: "js/1006" */ "./pages/web/testimonials.vue");
const ContactUsPage = () => import(/* webpackChunkName: "js/1007" */ "./pages/web/contact_us.vue");
const AboutPage = () => import(/* webpackChunkName: "js/1007" */ "./pages/web/about.vue");

const web_routes = [
    // Front Pages
    { path: "/", name: "IndexPage", component: IndexPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/our-specialties", name: "OurSpecialtyPage", component: OurSpecialtyPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/order", name: "OrderPage", component: OrderPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/checkout", name: "CheckoutPage", component: CheckoutPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/checkout-confirmation", name: "CheckoutConfirmationPage", component: CheckoutConfirmationPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/menu", name: "DishMenuPage", component: DishMenuPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/testimonials", name: "TestimonialsPage", component: TestimonialsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/contact-us", name: "ContactUsPage", component: ContactUsPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
    { path: "/about-us", name: "AboutPage", component: AboutPage, meta: { requiresAuth: false, isAdminAuthentication: false, isWeb: true } },
];

export default web_routes;
