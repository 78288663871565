<template>
  <footer class="footer-bottom">
    <div class="footer-bottom_container">
      <div class="row">
        <div class="col-xl-3 col-lg-6 col-12 px-4 text-center text-lg-start">
          <router-link :to="{ name: 'IndexPage' }" class="food-header-text-logo" id="foodLogo">Salu-Salo</router-link>
          <p class="footer-bottom_container_description">At Salu-Salo, Every Meal is a Celebration! Good food, great company—because every day is worth celebrating!</p>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 px-4">
          <h6 class="footer-bottom_container_title">Restaurant Hours</h6>
          <p class="footer-bottom_container_info">Sunday - Friday</p>
          <p class="footer-bottom_container_info">11:30am - 9:00pm</p>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 pe-0 pe-lg-5">
          <h6 class="footer-bottom_container_title">Restaurant Hours</h6>
          <p class="footer-bottom_container_info">123 Mabuhay Street Garden Villas, Sta. Rosa, Laguna</p>
        </div>
        <div class="col-xl-3 col-lg-6 col-12 px-4 text-center text-lg-start mt-3">
          <img src="/public/images/web/adobo-image.png" alt="widget photo" class="footer-bottom_container_foot-img" />
        </div>
      </div>
    </div>
    <hr />
    <p class="footer-bottom_copyright">Copyright 2024 Salu-Salo</p>
  </footer>
</template>