// export const onDatatable = (name, is_add, lengthDisplay = 10, padding = "pb-2") => {
//     $(name).DataTable({
//         autoWidth: false,
//         responsive: true,
//         order: [],
//         stateSave: true,
//         columnDefs: [
//             { responsivePriority: 1, targets: 0 },
//             { responsivePriority: 2, targets: -1 },
//         ],
//         lengthMenu: [
//             [lengthDisplay, 25, 50],
//             [lengthDisplay, 25, 50],
//         ],
//         // dom: `<"row justify-between ${padding}"<"col-12 mt-2"f>><"datatable-wrap radius-10 bg-light p-3 mb-4 rounded-0 border-0"t><"row coche-table-pagination-footer"<"col-12"p>>`,
//         dom: `<"row justify-between ${padding}"<"col-12 mt-2"f>><"datatable-wrap radius-10 bg-light p-3 mb-4 rounded-0 border-0"t <"row my-2"<"col-12 cms-pagination d-flex justify-content-center align-items-center"l<"mx-4"i>p>>>`,
//         language: {
//             search: "",
//             searchPlaceholder: "Search",
//             lengthMenu: "<div class='d-flex mt-3 align-items-center'><span class='d-none d-sm-inline-block cms-dataTable_length--font me-3'>Items per page: </span><div class='container_select'> _MENU_ </div></div>",
//             info: "<span class='cms-dataTable_length--font'>_START_ -_END_ of _TOTAL_ Entries</span>",
//             infoEmpty: "No records found",
//             infoFiltered: "( Total _MAX_  )",
//             paginate: {
//                 first: "First",
//                 last: "Last",
//                 next: "<em class='icon mdi mdi-arrow-right-thin regular-font fw-bold pointer'></em>",
//                 previous: "<em class='icon mdi mdi-arrow-left-thin regular-font fw-bold pointer'></em>",
//             },
//         },
//     });

//     if (is_add) {
//         $(".dataTables_filter").addClass("d-flex align-items-center flex-wrap").prepend('<button type="button" class="btn_cms btn_cms-save d-inline small-font py-2 px-5 fw-medium new_record fw-bold me-3">New Record</button>');
//     }
//     $(".dataTables_filter").children("label").addClass("mt-2 thead-label").children("input").addClass("radius-10 form-control form-control-md w-100 small-font px-3 py-2 filter-title-container");
// };

export const onDatatable = (name, is_add, lengthDisplay = 10, padding = "pb-2") => {
    $(name).DataTable({
        autoWidth: false,
        responsive: true,
        order: [],
        stateSave: true,
        columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
            [lengthDisplay, 25, 50],
            [lengthDisplay, 25, 50],
        ],
        dom: `<"row justify-between ${padding}"<"col-12 mt-2"f>><"datatable-wrap radius-10 mb-4 rounded-0 border-0"t><"row coche-table-pagination-footer"<"col-12"p>>`,
        //dom: `<"row justify-between ${padding}"<"col-12 mt-2"f>><"datatable-wrap radius-10 bg-light p-3 mb-4 rounded-0 border-0"t <"row my-2"<"col-12 cms-pagination d-flex justify-content-center align-items-center"l<"mx-4"i>p>>>`,
        language: {
            search: "",
            searchPlaceholder: "Search",
            lengthMenu: "<div class='d-flex mt-3 align-items-center'><span class='d-none d-sm-inline-block cms-dataTable_length--font me-3'>Items per page: </span><div class='container_select'> _MENU_ </div></div>",
            info: "<span class='cms-dataTable_length--font'>_START_ -_END_ of _TOTAL_ Entries</span>",
            infoEmpty: "No records found",
            infoFiltered: "( Total _MAX_  )",
            paginate: {
                first: "First",
                last: "Last",
                next: "<em class='icon mdi mdi-arrow-right-thin regular-font fw-bold pointer'></em>",
                previous: "<em class='icon mdi mdi-arrow-left-thin regular-font fw-bold pointer'></em>",
            },
        },
    });

    if (is_add) {
        $(".dataTables_filter").addClass("pl-2 d-flex justify-content-between align-items-center").prepend('<button type="button" class="btn btn-sm table-button text-white d-inline small-font py-2 px-3 fw-medium new_record fw-bold align-left">New Record</button>');
    }
    $(".dataTables_filter").children("label").addClass("mt-2 thead-label text-end").children("input").addClass("form-control form-control-md  small-font px-1 py-2 filter-title-container align-right");
};

export const fieldTypes = [
    {
        title: "Text & Rich Editor",
        subTitle: "Fields that store strings.",
        fields: [
            {
                name: "Text",
                icon: "mdi-cursor-text",
                input_type: "text",
                type: "field",
                has_placeholder: true,
            },
            {
                name: "Textarea",
                icon: "mdi-equal-box",
                input_type: "textarea",
                type: "html_element",
                has_placeholder: true,
            },
            {
                name: "Summernote",
                icon: "mdi-equal-box",
                input_type: "textarea",
                type: "summernote",
                has_placeholder: true,
            },
        ],
    },
    {
        title: "Buttons & Controls",
        subTitle: "Fields that provide selectable options or buttons that can control logic.",
        fields: [
            {
                name: "Select",
                icon: "mdi-equal-box",
                input_type: "select",
                type: "html_element",
                is_list: true,
                has_placeholder: false,
            },
            {
                name: "Radio Buttons",
                icon: "mdi-equal-box",
                input_type: "radio",
                type: "control",
                is_list: true,
                has_placeholder: false,
            },
            {
                name: "Range",
                icon: "mdi-equal-box",
                input_type: "range",
                type: "control",
                has_placeholder: false,
            },
            {
                name: "Checkboxes",
                icon: "mdi-equal-box",
                input_type: "checkbox",
                type: "control",
                is_list: true,
                has_placeholder: false,
            },
            {
                name: "Toggle",
                icon: "mdi-equal-box",
                input_type: "switch",
                type: "control",
                is_list: true,
                has_placeholder: false,
            },
        ],
    },
    {
        title: "Media",
        subTitle: "Fields that store images, videos, or other media.",
        fields: [
            {
                name: "Image",
                icon: "mdi-cursor-text",
                input_type: "file",
                type: "asset",
                has_placeholder: false,
            },
            {
                name: "Icon",
                icon: "mdi-cursor-text",
                input_type: "file",
                type: "asset",
                has_placeholder: false,
            },
            {
                name: "Video",
                icon: "mdi-cursor-text",
                input_type: "file",
                type: "asset",
                has_placeholder: false,
            },
        ],
    },
    {
        title: "Number",
        subTitle: "Fields that store numbers or integers.",
        fields: [
            {
                name: "Integer",
                icon: "mdi-cursor-text",
                input_type: "number",
                type: "field",
                has_placeholder: true,
            },
        ],
    },
    {
        title: "Special",
        subTitle: "These fields are special, each in their own way.",
        fields: [
            {
                name: "Date",
                icon: "mdi-cursor-text",
                input_type: "date",
                type: "field",
                has_placeholder: false,
            },
            {
                name: "Color",
                icon: "mdi-cursor-text",
                input_type: "color",
                type: "field",
                has_placeholder: false,
            },
            {
                name: "URL",
                icon: "mdi-cursor-text",
                input_type: "url",
                type: "field",
                has_placeholder: true,
            },
            {
                name: "Block",
                icon: "mdi-cursor-text",
                input_type: "div",
                type: "division",
            },
            {
                name: "Divider",
                icon: "mdi-cursor-text",
                input_type: "hr",
                type: "division",
            },
        ],
    },
];

export const callRecaptcha = async (context, action, isShowBadge = true) => {
    await context.$recaptchaLoaded();
    const token = await context.$recaptcha(action);

    context.google_token = token;
    const recaptchaInstance = context.$recaptchaInstance;
    if (isShowBadge) {
        recaptchaInstance.value.showBadge();
    } else {
        recaptchaInstance.value.hideBadge();
    }

    return token;
};

String.prototype.slugify = function (separator = "-") {
    return this.toString()
        .normalize("NFD") // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, separator) // remove all previously split accents
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, separator) // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator);
};

String.prototype.unslugify = function (separator = "-") {
    return this.toString().replace(/-/g, " ");
};

String.prototype.truncate = function (limit) {
    return this.length > limit ? this.substring(0, limit) + "..." : this;
};

export const onSummerNote = (name, customHeight, onImageUploadCallback, onDeleteImageUploadCallback, onChangeCallback) => {
    $(name).summernote({
        placeholder: "",
        fontNames: ["Arial", "Arial Black", "Comic Sans MS", "Courier New", "Poppins", "Times New Roman"],
        tabsize: 2,
        height: customHeight && customHeight != "" ? customHeight : 300,
        disableDragAndDrop: true,
        toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "underline", "clear", "size"]],
            ["fontsize", ["fontsize"]],
            ["fontname", ["fontname"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph", "height"]],
            ["insert", ["link", "picture"]],
            ["view", ["codeview"]],
        ],
        popover: {
            image: [
                ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
                ["float", ["floatLeft", "floatRight", "floatNone"]],
                ["remove", ["removeMedia"]],
            ],
            link: [["link", ["linkDialogShow", "unlink"]]],
            table: [
                ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
                ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
            ],
            air: [
                ["color", ["color"]],
                ["font", ["bold", "underline", "clear"]],
                ["para", ["ul", "paragraph"]],
                ["table", ["table"]],
                ["insert", ["link", "picture"]],
            ],
        },
        callbacks: {
            onImageUpload: function (data) {
                if (typeof onImageUploadCallback === "function") {
                    onImageUploadCallback(data[0]);
                }
            },
            onMediaDelete: function (data) {
                if (typeof onDeleteImageUploadCallback === "function") {
                    onDeleteImageUploadCallback(data);
                }
            },
            onChange: function (contents) {
                if (onChangeCallback) {
                    onChangeCallback(contents); // Pass the content back to the component if provided
                }
            },
        },
    });
};

export const handleParseStringifiedData = (section_array) => {
    return section_array.map((section) => {
        const sectionContent = JSON.parse(section.pageSectionContent);
        if (sectionContent.files && typeof sectionContent.files === "object" && Object.keys(sectionContent.files).length > 0) {
            sectionContent.files = Object.keys(sectionContent.files).map((file) => {
                try {
                    if (typeof sectionContent.files[file] === "string") {
                        return JSON.parse(sectionContent.files[file]);
                    } else {
                        return sectionContent.files[file];
                    }
                } catch (e) {
                    console.error("Error parsing file:", file, e);
                    return sectionContent.files[file];
                }
            });
        }
        return sectionContent;
    });
};

export const handleCurrencyParse = (amount) => {
    return (
        "₱" +
        parseFloat(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, ",")
    );
};
