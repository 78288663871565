import axios from "axios";

let queries = {
    front_queries: `query front($action_type: String!, $slug: String) {
        front(action_type: $action_type, slug: $slug) {
            page {
                pageID,
                pageTitle,
                originalID,
                pageSlug,
                pageIsLive,
                pageMeta,
                pageHeader,
                dateCreated,
                page_sections {
                    pageSectionID,
                    pageSection_title,
                    pageSectionContent,
                    pageSectionPageID,
                    pageSectionPosition,
                }
            },
            component {
                originalID,
                pageHeader,
                page_sections {
                    pageSectionID,
                    pageSection_title,
                    pageSectionContent,
                    pageSectionPageID,
                    pageSectionPosition,
                }
            },
            categories {
                original_id,
                name,
            },
            dishes {
                original_id,
                name,
                price,
                description,
                briefdescription,
                image,
                coverimage,
                category,
                 {
                    original_id
                    name,
                }
            },
            testimonials {
                original_id,
                name,
                company,
                description,
                image,
            },
        }
    }`,

    send_contact_us_email: `query front($action_type: String!, $name: String!, $email: String!, $message: String!, $mobile: String) {
        front(action_type: $action_type, name: $name, email: $email, message: $message, mobile: $mobile) {
            error,
            message
        }
    }`,
};

const front_query = (queryName, queryVariables) => {
    let options = {
        url: "/graphql",
        method: "POST",
        data: {
            query: queries[queryName],
        },
    };

    if (queryVariables) {
        options.data.variables = queryVariables;
    }
    return axios(options);
};

export default front_query;
