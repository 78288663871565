<template>
  <div>
    <div class="app-menu navbar-menu">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <!-- Light Logo-->
        <a href="javaScript:void(0);" class="logo">
          <span class="logo-sm">
            <img src="/public/images/logo_mobile.png" alt="mobile_logo" width="35" />

            <!-- <h4 class="my-4 text-dark fw-bold">PCMS</h4> -->
          </span>
          <span class="logo-lg">
            <img src="/public/images/PrimeLogo.png" alt="" class="mt-4 mb-3" />
            <!-- <h4 class="my-4 fw-bold">Pocket.CMS</h4> -->
          </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
          <i class="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar" data-simplebar>
        <div class="container-fluid">
          <div id="two-column-menu"></div>
          <ul class="navbar-nav" id="navbar-nav">
            <li class="menu-title"><span data-key="t-menu">Menu</span></li>

            <li class="nav-item">
              <router-link class="nav-link menu-link" :to="{ name: 'DashboardPage' }"><i class="mdi mdi-puzzle-outline"></i> <span data-key="t-widgets">Dashboard</span> </router-link>
            </li>
            <li class="nav-item" v-if="isSuperAdmin">
              <router-link class="nav-link menu-link" :to="{ name: 'AdministratorPage' }"><i class="mdi mdi-account-tie"></i> <span data-key="t-widgets">Administrators</span> </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link menu-link" :to="{ name: 'DynamicPage' }"><i class="mdi mdi-book-open-page-variant-outline"></i> <span data-key="t-widgets">Pages</span> </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link menu-link" :to="{ name: 'TestimonialPage' }"><i class="mdi mdi-account-voice"></i> <span data-key="t-widgets">Testimonial</span> </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link menu-link" :to="{ name: 'CategoryPage' }"><i class="mdi mdi-shape"></i> <span data-key="t-widgets">Category</span> </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link menu-link" :to="{ name: 'DishesPage' }"><i class="mdi mdi-food"></i> <span data-key="t-widgets">Dishes</span> </router-link>
            </li>
            <li class="nav-item" v-if="isSuperAdmin">
              <router-link class="nav-link menu-link" :to="{ name: 'BlueprintPage' }"><i class="mdi mdi-fingerprint"></i> <span data-key="t-widgets">Blueprints</span> </router-link>
            </li>
          </ul>
        </div>
        <!-- Sidebar -->
      </div>

      <div class="sidebar-background"></div>
    </div>
    <!-- Left Sidebar End -->
    <!-- Vertical Overlay-->
    <div class="vertical-overlay"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWeb: false,
      isPortal: false,
    };
  },
  computed: {
    isSuperAdmin() {
      return this.$store.state.adminRecord ? this.$store.state.adminRecord.isSuperAdmin : false;
    },
  },
};
</script>
