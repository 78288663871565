import { createApp } from "vue";
import Vuex from "vuex";
import { createWebHistory, createRouter } from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import { onDatatable } from "./helper.js";
import web_routes from "./routes_web.js";
//import admin_query from "./admin_queries.js";
import front_query from "./queries.js";

// for stylings
import "toastify-js/src/toastify.css";
import "sweetalert2/dist/sweetalert2.min.css";

let allRoutes = [];
allRoutes = allRoutes.concat(web_routes);

// creating the router and reading the routes file
const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        } else {
            return { top: 0, left: 0, behavior: "smooth" };
        }
    },
});

const store = new Vuex.Store({
    state: {
        orders: [],
        orderCount: 0,
        subTotal: 0,
    },

    mutations: {
        pushOrder(state, item) {
            state.orders.push(item);
            state.orderCount = state.orders.length;
        },
        removeOrder(state, id) {
            state.orders = state.orders.filter((item) => item.original_id !== id);
            state.orderCount = state.orders.length;
        },
        clearOrders(state) {
            state.orders = [];
            state.orderCount = 0;
        },
    },
});

router.beforeEach((to, from, next) => {
    // ADMIN
    if (to.matched.some((m) => m.meta.isAdminAuthentication === true)) {
        if (to.matched.some((m) => m.meta.hasAdminAccess === false)) {
            window.location.href = "/admin/dashboard";
        }
        if (sessionStorage.getItem("admin_api_token") === null || sessionStorage.getItem("login_type") === null) {
            window.location.href = "/admin";
            sessionStorage.clear();
            return;
        }
    } else {
        if (to.matched.some((m) => m.meta.requiresAuth === false)) {
            if (sessionStorage.getItem("login_type") != null) {
                sessionStorage.clear();
                window.location.href = "/admin";
            }
        }
    }
    next();
});
const head = createHead();
const vueApp = createApp(App);
//vueApp.config.globalProperties.$admin_query = admin_query;
vueApp.config.globalProperties.$front_query = front_query;
vueApp.config.globalProperties.$onDatatable = onDatatable;

// mounting the app to the router
vueApp.use(VueReCaptcha, {
    siteKey: "6LfJ_TUqAAAAALv5fPtunLuoAq-F0rYEz1GGXxxQ",
    loaderOptions: {
        autoHideBadge: true, // Set this to false if you want to show the badge
    },
});
vueApp.use(VueSweetalert2);
vueApp.use(router);
vueApp.use(head);
vueApp.use(store);
vueApp.mixin({
    created() {
        if (this.$options.metaInfo && typeof this.$options.metaInfo === "function") {
            this.$head = this.$head || head;

            // Add head objects only if meta data is ready
            this.$nextTick(() => {
                const meta = this.metaInfo();
                if (meta) this.$head.addHeadObjs(meta);
            });
        }
    },
});

vueApp.mount("#app");
