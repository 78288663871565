<template>
  <header class="food-header">
    <router-link :to="{ name: 'IndexPage' }" class="food-header-text-logo" id="foodLogo">Salu-Salo</router-link>

    <nav>
      <ul>
        <li v-for="link in navLinks" :key="link.name" class="item">
          <router-link class="text-white" id="navlink" :to="{ name: link.name }">
            {{ link.label }}
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="food-header_auth-btn">
      <router-link :to="{ name: 'OrderPage' }" class="position-relative">
        <span>Order Now</span> <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">{{ ordersCount }} <span class="visually-hidden">orders</span></span>
        <img src="/public/images/icon/contact-right.svg" alt="contact-right-icon" />
      </router-link>
    </div>

    <div class="food-header-burger_header" id="burgerBtn" @click="toggleSidebar">
      <span class="food-header-burger_header_line" v-show="is_active"></span>
      <span class="food-header-burger_header_line" :class="!is_active ? 'food-header-burger_header_line-active' : ''"></span>
      <span class="food-header-burger_header_line" v-show="is_active"></span>
    </div>

    <div class="" :class="!is_active ? 'food-header-burger_active' : 'food-header-burger_menu'">
      <ul>
        <li v-for="link in navLinks" :key="link.name">
          <router-link class="text-white" :to="{ name: link.name }" @click="closeSidebar">
            {{ link.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </header>
</template>
  
<script>
export default {
  data() {
    return {
      is_active: true,
      mybutton: null,
      currentPage: this.$route.name,
    };
  },
  computed: {
    navLinks() {
      return [
        { name: "DishMenuPage", label: "Our Menu" },
        { name: "ContactUsPage", label: "Contact Us" },
        { name: "AboutPage", label: "About Us" },
      ];
    },
    ordersCount() {
      return this.$store.state.orderCount;
    },
  },
  methods: {
    toggleSidebar() {
      this.is_active = !this.is_active;
    },
    closeSidebar() {
      this.is_active = true;
    },
    handleNavColors(page) {
      let pageName = page.name;
      let is_hidden = page.hidden;
      if (this.currentPage === pageName) {
        return `text-info fw-bold ${is_hidden ? "d-none" : ""}`;
      }
      return `text-white ${is_hidden ? "d-none" : ""}`;
    },
    handleSidebarNavColors(page) {
      let pageName = page.name;
      let is_hidden = page.hidden;

      if (is_hidden) {
        return "d-none";
      }

      if (this.currentPage === pageName) {
        return "text-info fw-bold";
      } else {
        return "text-white";
      }
    },
    handleScroll() {
      if (window.scrollY > 150) {
        foodLogo.src = "/public/images/food_gate-logo.png";
        $(".food-header").addClass("bg-dark");
        this.showBackToTop(true);
      } else {
        $(".food-header").addClass("bg-dark");
        $(".food-header").removeClass("bg-dark");
        this.showBackToTop(false);
      }
    },
    showBackToTop(visible) {
      this.mybutton.style.display = visible ? "block" : "none";
    },
    updateNavColors(scrollY) {
      const navLinks = document.querySelectorAll("#navlink");
      navLinks.forEach((link) => {
        const linkPage = link.textContent === "Home" ? "IndexPage" : `${link.textContent}Page`;
        if (linkPage === this.currentPage) {
          link.classList.add("text-info", "fw-bold");
        } else {
          link.classList.add("text-white");
        }
      });
    },
  },
  mounted() {
    this.mybutton = document.getElementById("back-to-top");
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route(to) {
      this.currentPage = to.name;
    },
  },
};
</script>
  