<template>
  <div>
    <div v-if="isWeb">
      <LandingHeader />
      <RouterView />
      <ScrollUpButton />
      <LandingFooter />
    </div>

    <div v-else-if="!isPortal && !isWeb">
      <div class="auth">
        <div class="left">
          <div>
            <h1 class="display-5">Pocket CMS:</h1>
            <h1 class="display-5">Edit. Post. Breathe.</h1>

            <p class="mt-4">Website management, simplified</p>
          </div>
        </div>
        <RouterView />
      </div>
    </div>

    <div v-else>
      <div id="layout-wrapper">
        <admin-header :admin_rec="admin_rec" />
        <AdminSidebar :admin_rec="admin_rec" />
        <!-- MAIN CONTENT -->
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="h-100">
                    <div class="row mb-3 pb-1">
                      <div class="col-12">
                        <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                          <div class="flex-grow-1">
                            <RouterView />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END MAIN CONTENT -->
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "./admin/includes/header.vue";
import AdminSidebar from "./admin/includes/sidebar.vue";
import LandingHeader from "./pages/includes/header.vue";
import LandingFooter from "./pages/includes/footer.vue";
import ScrollUpButton from "./pages/components/ScrollUpButton.vue";

export default {
  components: { AdminHeader, AdminSidebar, LandingHeader, LandingFooter, ScrollUpButton },
  data() {
    return {
      isWeb: true,
      isPortal: false,
      isAdminLoggedIn: false,
      admin_rec: {},
    };
  },
  provide() {
    return {
      // using the Arrow function to preserve 'this'
      authenticateUser: (value) => {
        if (value) {
          this.onPopulateAdminData();
        }
      },
    };
  },
  mounted() {
    const token = sessionStorage.getItem("admin_api_token");
    if (token) {
      this.isPortal = true;
      this.isAdminLoggedIn = true;
      this.onPopulateAdminData();
    }
  },
  methods: {
    onPopulateAdminData() {
      this.$admin_query("admin", {
        action_type: "current_admin",
      }).then((res) => {
        this.admin_rec = res.data.data.administrator[0];
        this.isPortal = true;
        this.isAdminLoggedIn = true;
        this.$store.commit("updateAdminRecord", this.admin_rec);
      });
    },
  },

  watch: {
    $route(to, from) {
      if (to.meta.isWeb) {
        this.isWeb = true;
      } else {
        this.isWeb = false;
      }
    },
  },
};
</script> 