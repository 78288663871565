<template>
  <div>
    <div class="loader" v-if="is_calling_api"></div>
    <div class="modal zoomIn" id="profile_modal" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content py-3 px-5">
          <div class="modal-header bg-white mb-5 align-items-start px-0">
            <div class="d-flex align-items-start">
              <div class="profile-user position-relative d-inline-block me-4">
                <img width="120" height="120" :src="display_image" class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                <div v-if="is_edit" class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input @change="onChangeFile()" ref="proFile" id="profile-img-file-input" type="file" class="profile-img-file-input" />
                  <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                    <span class="avatar-title rounded-circle bg-light text-body">
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <div class="mt-4">
                <h3 class="text-dark fw-bold">{{ `${firstname} ${lastname}` }}</h3>
                <b>Administrator</b>
              </div>
            </div>
            <button type="button" class="btn btn-light fw-bold" @click="onCloseModal"><i class="mdi mdi-window-close"></i></button>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h5 class="fweight-semibold">Account Information</h5>
              <div class="d-flex align-items-center">
                <button class="btn_cms btn_cms-save small-font rounded px-3 py-0" @click="onEdit">{{ !is_edit ? "Edit" : " Cancel" }}</button>
                <button class="btn_cms btn_cms-edit small-font rounded px-4 ms-3 py-0" v-if="is_edit" @click="onSaveProfile">Save</button>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-lg-4 col-12">
                <label class="form-label fweight-semibold" for="steparrow-gen-info-email-input">Email</label>
                <p v-if="!is_edit" class="small-font">{{ email }}</p>
                <input v-else type="email" class="form-control" id="steparrow-gen-info-email-input" placeholder="Enter email" v-model="email" />
                <small class="text-danger">{{ email_error }}</small>
              </div>
              <div class="mb-3 col-lg-4 col-12">
                <label class="form-label fweight-semibold" for="steparrow-gen-info-password-input">Password</label>
                <p v-if="!is_edit">********</p>
                <input v-else type="password" class="form-control" id="steparrow-gen-info-password-input" placeholder="Enter password" v-model="password" />
                <small class="text-danger">{{ password_error }}</small>
              </div>
              <div class="col-lg-4 col-12">
                <label class="form-label fweight-semibold" for="steparrow-gen-info-confirm-password-input">Confirm Password</label>
                <p v-if="!is_edit">********</p>
                <input v-else type="password" class="form-control" id="steparrow-gen-info-confirm-password-input" placeholder="Enter confirm password" v-model="confirm_password" />
                <small class="text-danger">{{ confirm_password_error }}</small>
              </div>

              <h5 class="fweight-semibold">General Information</h5>
              <div class="col-12 col-lg-4">
                <div class="mb-3">
                  <label class="form-label fweight-semibold" for="steparrow-gen-info-firstname-input">Firstname</label>
                  <p class="small-font" v-if="!is_edit">{{ firstname }}</p>
                  <input v-else type="text" class="form-control" id="steparrow-gen-info-firstname-input" v-model="firstname" />
                  <small class="text-danger">{{ firstname_error }}</small>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="mb-3">
                  <label class="form-label fweight-semibold" for="steparrow-gen-info-username-input">Lastname</label>
                  <p class="small-font" v-if="!is_edit">{{ lastname }}</p>
                  <input v-else type="text" class="form-control" id="steparrow-gen-info-username-input" v-model="lastname" />
                  <small class="text-danger">{{ lastname_error }}</small>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="mb-3">
                  <label class="form-label fweight-semibold" for="steparrow-gen-info-mobile-input">Mobile</label>
                  <p class="small-font" v-if="!is_edit">{{ mobile }}</p>
                  <input v-else type="number" class="form-control" id="steparrow-gen-info-mobile-input" placeholder="Enter mobile" v-model="mobile" />
                  <small class="text-danger">{{ mobile_error }}</small>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-body">
            <div class="form-group">
              <form id="form-steps" class="form-steps" autocomplete="off">
                <div class="step-arrow-nav mb-4">
                  <ul class="nav nav-pills custom-nav nav-justified" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a href="1st" class="nav-link active" id="steparrow-gen-info-tab" data-bs-toggle="pill" data-bs-target="#steparrow-gen-info" type="button" role="tab" aria-controls="steparrow-gen-info" aria-selected="true" data-position="0">General</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a href="2nd" class="nav-link" id="steparrow-description-info-tab" disabled data-bs-toggle="pill" data-bs-target="#steparrow-description-info" type="button" role="tab" aria-controls="steparrow-description-info" aria-selected="false" data-position="1" tabindex="-1">Description</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a href="3rd" class="nav-link" id="pills-experience-tab" data-bs-toggle="pill" disabled data-bs-target="#pills-experience" type="button" role="tab" aria-controls="pills-experience" aria-selected="false" data-position="2" tabindex="-1">Finish</a>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                  <div class="tab-pane fade active show" id="steparrow-gen-info" role="tabpanel" aria-labelledby="steparrow-gen-info-tab">
                    <div>
                      <div class="mb-3">
                        <label class="form-label" for="steparrow-gen-info-email-input">Email</label>
                        <input type="email" class="form-control" id="steparrow-gen-info-email-input" placeholder="Enter email" v-model="email" />
                        <small class="text-danger">{{ email_error }}</small>
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="steparrow-gen-info-password-input">Password</label>
                        <input type="password" class="form-control" id="steparrow-gen-info-password-input" placeholder="Enter password" v-model="password" />
                        <small class="text-danger">{{ password_error }}</small>
                      </div>
                      <div>
                        <label class="form-label" for="steparrow-gen-info-confirm-password-input">Confirm Password</label>
                        <input type="password" class="form-control" id="steparrow-gen-info-confirm-password-input" placeholder="Enter confirm password" v-model="confirm_password" />
                        <small class="text-danger">{{ confirm_password_error }}</small>
                      </div>
                    </div>
                    <div class="d-flex align-items-start gap-3 mt-4">
                      <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="steparrow-description-info-tab" @click="onNextStep('steparrow-gen-info')"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to more info</button>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="steparrow-description-info" role="tabpanel" aria-labelledby="steparrow-description-info-tab">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label" for="steparrow-gen-info-firstname-input">Firstname</label>
                          <input type="text" class="form-control" id="steparrow-gen-info-firstname-input" v-model="firstname" />
                          <small class="text-danger">{{ firstname_error }}</small>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label" for="steparrow-gen-info-username-input">Lastname</label>
                          <input type="text" class="form-control" id="steparrow-gen-info-username-input" v-model="lastname" />
                          <small class="text-danger">{{ lastname_error }}</small>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label class="form-label" for="steparrow-gen-info-mobile-input">Mobile</label>
                          <input type="text" class="form-control" id="steparrow-gen-info-mobile-input" placeholder="Enter mobile" v-model="mobile" />
                          <small class="text-danger">{{ mobile_error }}</small>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Upload Image</label>
                          <input class="form-control" type="file" id="formFile" ref="proFile" @change="onChangeFile()" />
                        </div>
                        <img :src="display_image" width="120" />
                      </div>
                    </div>
                    <div class="d-flex align-items-start gap-3 mt-4">
                      <button type="button" class="btn btn-light btn-label previestab" data-previous="steparrow-gen-info-tab" @click="onPrevStep('steparrow-gen-info-tab')"><i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to General</button>
                      <button type="button" class="btn btn-success btn-label right ms-auto nexttab" data-nexttab="pills-experience-tab" @click="onNextStep('steparrow-description-info', true)"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Submit</button>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="pills-experience" role="tabpanel" aria-labelledby="pills-experience-tab">
                    <div class="text-center">
                      <div class="avatar-md mt-5 mb-4 mx-auto">
                        <div class="avatar-title bg-light text-success display-4 rounded-circle">
                          <i class="ri-checkbox-circle-fill"></i>
                        </div>
                      </div>
                      <h5>Well Done !</h5>
                      <p class="text-muted">{{ responseMessage }}</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["admin"],
  data() {
    return {
      is_calling_api: false,
      responseMessage: "",
      is_edit: false,

      email: "",
      password: "",
      confirm_password: "",

      mobile: "",
      firstname: "",
      lastname: "",
      selectedFile: null,
      display_image: null,

      // Errors
      firstname_error: "",
      lastname_error: "",
      email_error: "",
      password_error: "",
      confirm_password_error: "",
      mobile_error: "",
    };
  },
  watch: {
    admin: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.email = newVal.email;
          this.firstname = newVal.firstname;
          this.lastname = newVal.lastname;
          this.mobile = newVal.mobile;
          this.display_image = `/public/uploads/administrator/${newVal?.original_id}/medium/${newVal?.image}`;
        }
      },
      deep: true,
    },
  },
  methods: {
    onEdit() {
      this.is_edit = !this.is_edit;
    },
    onChangeFile(e) {
      let file = this.$refs.proFile.files[0];
      this.selectedFile = file;
      this.onDisplayImage(file, "display_image");
    },

    onDisplayImage(file, varName) {
      this[varName] = URL.createObjectURL(file);
    },

    onCloseModal() {
      this.$emit("close");
    },

    onSaveProfile() {
      this.onCloseModal();
      this.is_calling_api = true;
      this.is_edit = false;
      this.$admin_query("save_admin", {
        admin: {
          email: this.email,
          password: this.password,
          confirm_password: this.confirm_password,
          firstname: this.firstname,
          lastname: this.lastname,
          mobile: this.mobile,
          administrator_id: this.admin.administrator_id,
          action_type: "update_record",
        },
        file: this.selectedFile,
      }).then((res) => {
        const response = res.data.data.administrator;
        this.is_calling_api = false;
        if (res.data.errors) {
          let errors = Object.values(res.data.errors[0].extensions.validation).flat();
          let errors_keys = Object.keys(res.data.errors[0].extensions.validation).flat();

          const error_message = (name, index) => {
            this[name] = errors_keys.some((q) => q == index) ? errors[errors_keys.indexOf(index)] : "";
          };

          error_message("email_error", "admin.email");
          error_message("confirm_password_error", "admin.confirm_password");
          error_message("firstname_error", "admin.firstname");
          error_message("lastname_error", "admin.lastname");
          error_message("mobile_error", "admin.mobile");
        }

        if (response.error) return;
        this.responseMessage = response.message;

        this.$swal("Success", response.message, "success");
        this.$emit("updateImage", this.display_image);
      });
    },
  },
};
</script>