<template>
  <button @click="topFunction()" class="btn btn-info btn-icon landing-back-top" id="back-to-top">
    <i class="ri-arrow-up-line"></i>
  </button>
</template>
  
  <script>
export default {
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>